/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 767px) {
	#Banner .blacksection::before {
		display: none;
	}
	#Banner {
		width: 100%;
	}
	header {
		position: fixed;
		width: 100%;
		padding: 20px;
		z-index: 99999;
	}
	header .mobile-logo {
		display: block;
		width: 60px;
	}
	#Banner .logo {
		display: none;
	}
	.mobile-logo img {
		width: 100%;
		height: auto;
	}
	#Banner h1 {
		font-size: 40px;
		right: auto;
		top: 50%;
		transform: translate(-50%,-50%);
		opacity: 1;
		left: 50%;
		text-align: left;
	}
	.mobile-header {
		display: block;
	}
} 

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 768px) and (max-width: 991px){
	#Banner {
		width: 100%;
		height: 60vh;
	}
	header {
		position: fixed;
		width: 100%;
		padding: 20px;
		z-index: 99999;
	}
	header .mobile-logo {
		display: block;
		width: 60px;
	}
	#Banner .logo {
		display: none;
	}
	.mobile-logo img {
		width: 100%;
		height: auto;
	}
	#Banner h1 {
		right: 16%;
		opacity: 1;
	}
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 992px) and (max-width: 1024px){
	#Banner .blacksection::before {
		width: 1200px;
	}
	#Banner h1 {
		font-size: 60px;
	}
	#OurWork .each-work {
		width: 30.6%;
	}
	#OurBlog .each-block::before {
		right: -53px;
	}
	#OurBlog .each-block.two::before {
		left: -52px;
	}
	#Testimonials .testi-wrapper .each-testi {
		width: 31%;
	}
}