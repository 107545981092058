
* {
  box-sizing: border-box;
}

img {
  vertical-align: middle;
}

/* Position the image container (needed to position the left and right arrows) */
.container-show {
  position: relative;
}
/* Add a pointer when hovering over the thumbnail images */
.cursor {
  cursor: pointer;
}

/* Add a transparency effect for thumnbail images */
.show {
  opacity: 0.6;
}

.active,
.show:hover {
  opacity: 1;
}
