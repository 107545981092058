.footer{
    left: 0;
    padding-top: 20px;
    bottom: 0;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 50px;
    background-color: #f2f2f2;
    font-weight: bold;
    color: #212121;
    text-align: center;
}
.logo-footer{
    float: left;
    width: 100px;
    margin-bottom: 10px;
}

.col-flex{
    text-align: left;
    float: left;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.col-flex-contact{
    text-align: left;
    float: left;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 60px;
}

a{
    font-size: 0.8rem;
}

@media screen and (max-width: 500px) {
    .col-flex-contact{
        margin-left: 0px;
    }
}
