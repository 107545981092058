
.w3-badge {
  cursor: pointer;
  margin-top: 5px;
}
.w3-badge {
  height: 13px;
  width: 13px;
  padding: 0;
}
.flex-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.click-color-icon {
  background: #ad3235 !important;
}

.click-color-hover-icon:hover {
  background: #eb8d8e !important;
}

.border-color-icon {
  border-color: #ad3235 !important;
}

.icon-position {
  position: absolute;
  right: 15px;
  top: 140px;
  width: 100%;
  z-index: 100;
}

.size-banner{
  height: 400px;
}

@media screen and (max-width: 500px){
  .icon-position {
    right: 10px;
    top: 90px;
  }
  .size-banner{
    height: 250px;
  }
}
