@font-face {
  font-family: Butler;
  src: url("./assets/fonts/Butler-Bold.woff");
  font-weight: bold;
}
@font-face {
  font-family: Butler;
  src: url("./assets/fonts/Butler.woff");
}
@font-face {
  font-family: Butler;
  src: url("./assets/fonts/Butler-Light.woff");
  font-weight: lighter;
}
body {
  margin: 0;
  font-family: Butler,'Kanit' !important;
  -webkit-font-smoothing: 'Butler','Kanit';
  -moz-osx-font-smoothing: 'Butler','Kanit';
  font-size: 20px;
}
body, html {
  height: 100%;
  margin: 0;
}
code {
  font-family: 'Butler','Kanit';
}

