
.full-home {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.full-home .content-home {
  background-color: rgba(0, 0, 0, 0.75) !important;
  height: 100%;
  width: 100%;
  display: grid;
}
.full-home .content-home img {
  transform: translate3d(0, 0, 0);
  top: 0;
  left: 50%;
  margin: auto;
  margin-top: 12vh;
  animation: zoomin 0.6s ease;
  align-self: center;
  object-fit: contain;
  width: calc(80%);
  height: calc(80%);
  filter: drop-shadow(0 3px 12px rgb(63, 11, 11));
}

.gallery-home .gallery-item-video-home {
  transition: grid-row-start 300ms linear;
  transition: transform 300ms ease;
  transition: all 0.5s ease;
  cursor: pointer;
}
.gallery-home .gallery-item-video-home:hover {
  transform: scale(1.025);
}

.byebye-home {
  opacity: 0;
}
.byebye-home:hover {
  transform: scale(0.2) !important;
}

.gallery-home {
  display: grid;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-auto-rows: 8px;
}
.gallery-home img {
  max-width: 100%;
  transition: all 0.5s ease;
}

.gallery-home video {
  max-width: 100%;
  transition: all 0.5s ease;
}

.gallery-home .content-home {
  padding: 4px;
}
.gallery-home .gallery-item-home {
  transition: grid-row-start 300ms linear;
  transition: transform 300ms ease;
  transition: all 0.5s ease;
  cursor: pointer;
}
.gallery-home .gallery-item-home:hover {
  transform: scale(1.025);
}
@media (max-width: 600px) {
  .gallery-home {
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  }
}
@media (max-width: 400px) {
  .gallery-home {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}
@-moz-keyframes zoomin {
  0% {
    max-width: 100%;
    filter: blur(4px);
  }
  20% {
    max-width: 100%;
    filter: blur(2px);
  }
  70% {
    max-width: 100%;
  }
  100% {
    max-width: 100%;
  }
}
@-webkit-keyframes zoomin {
  0% {
    max-width: 100%;
    filter: blur(4px);
  }
  20% {
    max-width: 100%;
    filter: blur(2px);
  }
  70% {
    max-width: 100%;
  }
  100% {
    max-width: 100%;
  }
}
@-o-keyframes zoomin {
  0% {
    max-width: 100%;
    filter: blur(4px);
  }
  20% {
    max-width: 100%;
    filter: blur(2px);
  }
  70% {
    max-width: 100%;
  }
  100% {
    max-width: 100%;
  }
}
@keyframes zoomin {
  0% {
    max-width: 100%;
    filter: blur(4px);
  }
  20% {
    max-width: 100%;
    filter: blur(2px);
  }
  70% {
    max-width: 100%;
  }
  100% {
    max-width: 100%;
  }
}
